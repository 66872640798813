<template>
  <b-navbar wrapper-class="container" :transparent="true" spaced class="nav">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img :src="require('@/assets/logo.png')" alt="LAZ-BOY" />
      </b-navbar-item>
    </template>
    <template slot="start">
      <b-navbar-item tag="router-link" to="/"> HOME </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/concept' }">
        CONCEPT
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/products' }">
        PRODUCT
      </b-navbar-item>
      <b-navbar-item tag="router-link" :to="{ path: '/event-gallery' }">
        EVENT GALLERY
      </b-navbar-item>
      <b-navbar-dropdown label="TRAINING" hoverable>
        <b-navbar-item tag="router-link" :to="{ path: '/training' }">
          TRAINING 2022
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/training/2021' }">
          TRAINING 2021
        </b-navbar-item>
      </b-navbar-dropdown>
      <!-- 
      <b-navbar-item
        v-if="!loggedIn()"
        tag="router-link"
        :to="{ path: '/register' }"
      >
        REGISTER
      </b-navbar-item>
      <b-navbar-item
        v-if="!loggedIn()"
        tag="router-link"
        :to="{ path: '/login' }"
      >
        LOGIN
      </b-navbar-item>
      <Button
        label="LOGOUT"
        width="10px"
        :no-padding="true"
        class="px-12"
        @click="onLogout"
        v-if="loggedIn()"
      /> -->
    </template>
  </b-navbar>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      name: "User",
    };
  },
  methods: {
    ...mapActions({
      logout: "user/logout",
    }),
    loggedIn() {
      return this.token != null;
    },
    onLogout() {
      this.token = null;
      this.logout();
      this.$router.replace("/");
    },
  },
  computed: {
    ...mapState({
      token: (state) => state.token,
      email: (state) => state.user.email,
    }),
  },
};
</script>
<style lang="scss" scoped>
.navbar a.navbar-item {
  color: #000;
  font-weight: 500;
}
.v-application a {
  color: #000;
  &.router-link-exact-active {
    color: #6da6b5;
  }
}
.training-sub-menu {
  background: #dfdfdf;
  cursor: pointer;
}
.training-sub-menu:hover {
  background: white;
}
</style>